<template>
  <BaseList
    :list-query="countries"
    route="country"
    locale-section="pages.countries"
  >
    <template v-slot:extra="{ element: { sv, en } }">
      ( {{ sv }} / {{ en }} )
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CountryList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      countries: gql`
        query countries {
          list: countries {
            id
            name: country
            country
            sv
            en
          }
        }
      `
    };
  }
};
</script>
